import './Darkbox.css'
import React from 'react'
import loading_image from '../../images/icons/loading.svg'
import close_image from '../../images/icons/close.svg'
import back_image from '../../images/icons/back.svg'
import next_image from '../../images/icons/next.svg'
import $ from 'jquery'

class Darkbox extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            visible: false,
            currentImageIndex: NaN,
            currentImageURL: null,
            imageLoaded: true
        }
        this.addKeyListener = this.addKeyListener.bind(this)
        this.removeKeyListener = this.removeKeyListener.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.addImage = this.addImage.bind(this)
        this.showImage = this.showImage.bind(this)
        this.showPreviousImage = this.showPreviousImage.bind(this)
        this.showNextImage = this.showNextImage.bind(this)
        this.hideImage = this.hideImage.bind(this)

        this.images = {}
    }

    addKeyListener()
    {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    removeKeyListener()
    {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown(event)
    {
        switch(event.code)
        {
            case 'Escape':
                this.hideImage()
                break
            case 'ArrowLeft':
                this.showPreviousImage()
                break
            case 'ArrowRight':
                this.showNextImage()
                break
            default:
                break
        }
    }

    addImage(src, index)
    {
        this.images[index] = src
    }

    showImage(index)
    {
        let state = Object.assign({}, this.state)
        state.imageLoaded = false
        state.visible = true
        state.currentImageIndex = index
        this.setState(state)

        fetch(this.images[index])
            .then(response => {
                if(!response.ok)
                {
                    throw new Error("Fetch error")
                }
                return response.blob()
            })
            .then(response => {
                if(index === this.state.currentImageIndex)
                {
                    const localURL = URL.createObjectURL(response)
                    state = Object.assign({}, this.state)
                    state.imageLoaded = true
                    state.currentImageURL = localURL
                    this.setState(state)
                }
            })
            .catch(error => {console.log(error)})
    }

    showPreviousImage()
    {
        let imageIndexes = Array.from(Object.keys(this.images), x => parseInt(x))
        const index = imageIndexes.indexOf(this.state.currentImageIndex)
        const newIndex = index > 0 ? index - 1 : imageIndexes.length - 1
        this.showImage(imageIndexes[newIndex])
    }

    showNextImage()
    {
        let imageIndexes = Array.from(Object.keys(this.images), x => parseInt(x))
        const index = imageIndexes.indexOf(this.state.currentImageIndex)
        const newIndex = index >= 0 && index !== imageIndexes.length - 1 ? index + 1 : 0
        this.showImage(imageIndexes[newIndex])
    }

    hideImage(imageSrc)
    {
        let state = Object.assign({}, this.state)
        state.visible = false
        this.setState(state)
    }

    render()
    {
        const LoadingImage = () => {
            return (
                <img className="loading_image" src={loading_image} alt="Loading" />
            )
        }

        const FullImage = () => {
            return (
                <img className="content_image" src={this.state.currentImageURL} alt="Full" />
            )
        }

        $('body').css('overflow', this.state.visible ? 'hidden' : 'scroll')
        this.state.visible ? this.addKeyListener() : this.removeKeyListener()


        return (
            <div className={this.state.imageLoaded ? "darkbox" : "darkbox darkbox_stretched"} style={{display: this.state.visible ? 'block' : 'none'}} onKeyDown={() => {console.log("asdf")}}>
                <div className="darkbox_inner">
                    <div className="top_panel" onClick={this.hideImage}>
                        <img className="darkbox_button" src={close_image} alt="close button" />
                    </div>
                    <div className="arrange_horizontal">
                        <div className="left_panel" onClick={this.showPreviousImage}>
                            <img className="darkbox_button" src={back_image} alt="previous button" />
                        </div>
                        <div className="darkbox_content">
                            {this.state.imageLoaded ? <FullImage /> : <LoadingImage />}
                        </div>
                        <div className="right_panel" onClick={this.showNextImage}>
                            <img className="darkbox_button" src={next_image} alt="next button" />
                        </div>
                    </div>
                    <div className="bottom_panel" onClick={this.hideImage}></div>
                </div>
            </div>
        )
    }
}

export default Darkbox
