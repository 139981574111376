import './LocationPage.css'
import Map from './Map.js'

function LocationPage(props)
{
    return (
        <div className="content contact_page_content">
            <h3>Lokalizacja</h3>
            <Map src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2320.7015993146797!2d16.37574711587966!3d54.43290158022107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fe2ba54bcf62cf%3A0x49775c66c923d88c!2sRubin%20-%20dom%20go%C5%9Bcinny!5e0!3m2!1sen!2spl!4v1612472072557!5m2!1sen!2spl"} />
        </div>
    )
}

export default LocationPage
