import React, {useState, useCallback} from "react"
import './Gallery.css'
import ExpandableImage from '../ExpandableImage/ExpandableImage.js'
import Darkbox from '../Darkbox/Darkbox.js'

function Gallery(props)
{
    const [darkboxRef, setDarkboxRef] = useState(null)
    const onDarkboxRefChange = useCallback(node => {
        setDarkboxRef(node)
    }, [])

    return (
        <div className="gallery">
            {props.images.map(image => {return <div className="gallery_image" key={image.index}><ExpandableImage imageSrc={image.src} fullImageSrc={image.fullSrc} imageAlt={image.alt} index={image.index} darkbox={darkboxRef}/></div>})}
            <Darkbox ref={onDarkboxRefChange}/>
        </div>
    )
}

export default Gallery
