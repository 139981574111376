import './ContactPage.css'

function ContactPage(props)
{
    return (
        <div className="content contact_page_content">
            <div className="center_content">
                <h1>Rubin</h1>
                <h2>Dom gościnny</h2>
                <p>Krzysztof Libucha</p>
                <p><span>Tel.:</span> 507 017 513</p>
                <p><span>E-mail.:</span> pokojerubin@gmail.com</p>
                <p>Marynarska 20</p>
                <p>76-153, Darłowo</p>
            </div>
        </div>
    )
}

export default ContactPage
