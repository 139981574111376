import './ExpandableImage.css'
import React from 'react'

class ExpandableImage extends React.Component
{
    constructor(props)
    {
        super(props)
        this.addToDarkbox = this.addToDarkbox.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
    }

    addToDarkbox()
    {
        if(this.props.darkbox == null) return

        this.props.darkbox.addImage(this.props.fullImageSrc, this.props.index)
    }

    componentDidUpdate()
    {
        this.addToDarkbox();
    }

    handleClick()
    {
        this.props.darkbox.showImage(this.props.index)
    }

    render()
    {
        this.addToDarkbox();

        return (
            <div className="expandable_image">
                <img src={this.props.imageSrc} alt={this.props.imageAlt} onClick={this.handleClick}/>
            </div>
        )
    }
}

export default ExpandableImage
