import './PricelistPage.css'

function PricelistPage(props)
{
    return (
        <div className="content">
            <h3>Pokój 2-osobowy:</h3>
            <ul>
                <li>140 zł/doba (max 2 osoby)</li>
            </ul>

            <h3>Pokój 3-osobowy:</h3>
            <ul>
                <li>210 zł/doba (max 3 osoby)</li>
            </ul>

            <h3>Studio 4-osobowe:</h3>
            <ul>
                <li>280 zł/doba (max 4 osoby)</li>
            </ul>
        </div>
    )
}

export default PricelistPage
