import './Map.css'

function Map(props)
{
    return (
        <div class="map_container">
            <iframe src={props.src} frameborder="0" allowfullscreen="true" aria-hidden="false" tabindex="0" class="map_frame" title="location"></iframe>
        </div>
    )
}

export default Map
