import React, {useState, useCallback} from "react"
import './MainPage.css'
import Darkbox from '../Darkbox/Darkbox.js'
import ExpandableImage from '../ExpandableImage/ExpandableImage.js'

import pokoj_2os_3 from '../../images/pokoj_2os/pokoj_2os_balkon.JPG'
import jp2 from '../../images/dev/elegancko_jp2.jpg'

function MainPage(props)
{
    const [darkboxRef, setDarkboxRef] = useState(null)
    const onDarkboxRefChange = useCallback(node => {
        setDarkboxRef(node)
    }, [])

    return (
        <div className="content">
            <h3>Witamy</h3>
            <p>
                Serdecznie witamy Państwa na naszej stronie internetowej. Zapraszamy do skorzystania z prezentowanej poniżej oferty wypoczynku w Darłówku oraz spędzenia niezapomnianych chwil w przyjemnej atmosferze. Dobrze wiemy, że nie zawsze Wczasowicze są traktowani w otwarty i życzliwy sposób. My lubimy swoich Gości i dlatego stworzyliśmy miejsce, w którym sami chcielibyśmy odpocząć i zamieszkać na wczasach. Mamy nadzieję, że znajdą u nas Państwo wszystko, by dobrze i miło spędzić czas. Nasz dom położony jest w spokojnej i ładnej dzielnicy Darłówka Zachodniego, na osiedlu domków jednorodzinnych. W pobliżu: stołówki, kawiarenki, restauracje, bary, smażalnie ryb, sklepy. 
            </p>

            <h3>W naszej ofercie znajdą Państwo</h3>
            <ul>
                <li>Komfortowe pokoje z łazienkami, TV, Internet Wi-Fi (kuchnia ogólnodostępna z wyposażeniem do dyspozycji Gości: lodówka, kuchenka, kuchenka mikrofalowa, akcesoria kuchenne itp.)</li>
                <li>Na wyposażeniu każdego pokoju znajdują się ponadto: koc plażowy i parawan</li>
                <li>Bezpłatne miejsce parkingowe na terenie posesji</li>
                <li>Goście mają do dyspozycji z altanę ogrodową, grill</li>
            </ul>

            <h3>Pokoje</h3>
            <ul>
                <li>Pokój 2-osobowy z balkonem</li>
                <li>Pokój 3-osobowy z balkonem</li>
                <li>Pokój 3-osobowy</li>
                <li>Studio 4-osobowe 1 (2 pokoje)</li>
                <li>Studio 4-osobowe 2 (2 pokoje)</li>
            </ul>
            <p className="disclaimer">
                Doba hotelowa rozpoczyna się o godzinie 14:00 w dniu przyjazdu, a kończy o godzinie 10:00 w dniu wyjazdu. W przypadku wolnego pokoju możliwość wcześniejszego zakwaterowania.
            </p>

            <h3>Opinie Google</h3>
            <h4 className="link_heading">
                <a href="https://www.google.com/search?q=rubin+dom+go%C5%9Bcinny+dar%C5%82owo">
                    Link do opinii Google
                </a>
            </h4>

            <Darkbox ref={onDarkboxRefChange}/>
        </div>
    )
}

export default MainPage
