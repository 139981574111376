import React, {useState, useCallback} from "react"
import './RoomsPage.css'
// import ExpandableImage from '../ExpandableImage/ExpandableImage.js'
import Darkbox from '../Darkbox/Darkbox.js'
import Gallery from '../Gallery/Gallery.js'

import pokoj_2os_0 from '../../images/pokoj_2os/pokoj_2os_1_jasniejsze.JPG'
import pokoj_2os_1 from '../../images/pokoj_2os/pokoj_2os_1.JPG'
import pokoj_2os_2 from '../../images/pokoj_2os/pokoj_2os_2.JPG'
import pokoj_2os_3 from '../../images/pokoj_2os/pokoj_2os_balkon.JPG'
import pokoj_2os_4 from '../../images/pokoj_2os/pokoj_2os_jasniejsze.JPG'
import pokoj_2os_5 from '../../images/pokoj_2os/pokoj_2os_lazienka_1.JPG'
import pokoj_2os_6 from '../../images/pokoj_2os/pokoj_2os_lazienka_2.JPG'

import pokoj_2os_0_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_1_jasniejsze_thumbnail.jpg'
import pokoj_2os_1_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_1_thumbnail.jpg'
import pokoj_2os_2_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_2_thumbnail.jpg'
import pokoj_2os_3_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_balkon_thumbnail.jpg'
import pokoj_2os_4_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_jasniejsze_thumbnail.jpg'
import pokoj_2os_5_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_lazienka_1_thumbnail.jpg'
import pokoj_2os_6_thumbnail from '../../images/pokoj_2os_thumbnail/pokoj_2os_lazienka_2_thumbnail.jpg'

import pokoj_3os_balkon_0 from '../../images/pokoj_3_os_balkon/pokoj_3os_balkon_1.png'
import pokoj_3os_balkon_1 from '../../images/pokoj_3_os_balkon/pokoj_3os_balkon_2.png'
import pokoj_3os_balkon_2 from '../../images/pokoj_3_os_balkon/pokoj_3os_balkon_3.png'
import pokoj_3os_balkon_5 from '../../images/pokoj_3_os_balkon/6.jpg'
import pokoj_3os_balkon_6 from '../../images/pokoj_3_os_balkon/7.jpg'
import pokoj_3os_balkon_7 from '../../images/pokoj_3_os_balkon/8.jpg'

import pokoj_3os_balkon_0_thumbnail from '../../images/pokoj_3os_balkon_thumbnail/pokoj_3os_balkon_1_thumbnail.png'
import pokoj_3os_balkon_1_thumbnail from '../../images/pokoj_3os_balkon_thumbnail/pokoj_3os_balkon_2_thumbnail.png'
import pokoj_3os_balkon_2_thumbnail from '../../images/pokoj_3os_balkon_thumbnail/pokoj_3os_balkon_3_thumbnail.png'
import pokoj_3os_balkon_5_thumbnail from '../../images/pokoj_3os_balkon_thumbnail/6_thumbnail.jpg'
import pokoj_3os_balkon_6_thumbnail from '../../images/pokoj_3os_balkon_thumbnail/7_thumbnail.jpg'

import pokoj_3os_0 from '../../images/pokoj_3os/pokoj_3os_1.JPG'
import pokoj_3os_1 from '../../images/pokoj_3os/pokoj_3os_2.JPG'
import pokoj_3os_2 from '../../images/pokoj_3os/pokoj_3os_3.png'
import pokoj_3os_3 from '../../images/pokoj_3os/pokoj_3os_4.JPG'
import pokoj_3os_4 from '../../images/pokoj_3os/pokoj_3_os_5.JPG'

import pokoj_3os_0_thumbnail from '../../images/pokoj_3os_thumbnail/pokoj_3os_1_thumbnail.jpg'
import pokoj_3os_1_thumbnail from '../../images/pokoj_3os_thumbnail/pokoj_3os_2_thumbnail.jpg'
import pokoj_3os_2_thumbnail from '../../images/pokoj_3os_thumbnail/pokoj_3os_3_thumbnail.png'
import pokoj_3os_3_thumbnail from '../../images/pokoj_3os_thumbnail/pokoj_3os_4_thumbnail.jpg'
import pokoj_3os_4_thumbnail from '../../images/pokoj_3os_thumbnail/pokoj_3os_5_thumbnail.jpg'

import studio_gora_1 from '../../images/studio_gora/2.jpg'
import studio_gora_2 from '../../images/studio_gora/3.jpg'
import studio_gora_3 from '../../images/studio_gora/4.jpg'
import studio_gora_4 from '../../images/studio_gora/5.jpg'
import studio_gora_5 from '../../images/studio_gora/6.jpg'
import studio_gora_6 from '../../images/studio_gora/7.jpg'
import studio_gora_7 from '../../images/studio_gora/8.jpg'
import studio_gora_8 from '../../images/studio_gora/8.jpg'
import studio_gora_9 from '../../images/studio_gora/9.jpg'
import studio_gora_10 from '../../images/studio_gora/10.jpg'
import studio_gora_11 from '../../images/studio_gora/11.jpg'
import studio_gora_12 from '../../images/studio_gora/12.jpg'
import studio_gora_13 from '../../images/studio_gora/13.jpg'

import studio_gora_1_thumbnail from '../../images/studio_gora_thumbnail/2_thumbnail.jpg'
import studio_gora_2_thumbnail from '../../images/studio_gora_thumbnail/3_thumbnail.jpg'
import studio_gora_3_thumbnail from '../../images/studio_gora_thumbnail/4_thumbnail.jpg'
import studio_gora_4_thumbnail from '../../images/studio_gora_thumbnail/5_thumbnail.jpg'
import studio_gora_5_thumbnail from '../../images/studio_gora_thumbnail/6_thumbnail.jpg'
import studio_gora_6_thumbnail from '../../images/studio_gora_thumbnail/7_thumbnail.jpg'
import studio_gora_7_thumbnail from '../../images/studio_gora_thumbnail/8_thumbnail.jpg'
import studio_gora_8_thumbnail from '../../images/studio_gora_thumbnail/8_thumbnail.jpg'
import studio_gora_9_thumbnail from '../../images/studio_gora_thumbnail/9_thumbnail.jpg'
import studio_gora_10_thumbnail from '../../images/studio_gora_thumbnail/10_thumbnail.jpg'
import studio_gora_11_thumbnail from '../../images/studio_gora_thumbnail/11_thumbnail.jpg'
import studio_gora_12_thumbnail from '../../images/studio_gora_thumbnail/12_thumbnail.jpg'
import studio_gora_13_thumbnail from '../../images/studio_gora_thumbnail/13_thumbnail.jpg'

import studio_dol_1 from '../../images/studio_dol/1.jpg'
import studio_dol_2 from '../../images/studio_dol/2.jpg'
import studio_dol_3 from '../../images/studio_dol/3.jpg'
import studio_dol_4 from '../../images/studio_dol/4.jpg'
import studio_dol_5 from '../../images/studio_dol/5.jpg'
import studio_dol_6 from '../../images/studio_dol/6.jpg'
import studio_dol_7 from '../../images/studio_dol/7.jpg'
import studio_dol_8 from '../../images/studio_dol/8.jpg'
import studio_dol_9 from '../../images/studio_dol/9.jpg'
import studio_dol_10 from '../../images/studio_dol/10.jpg'
import studio_dol_11 from '../../images/studio_dol/11.jpg'
import studio_dol_12 from '../../images/studio_dol/12.jpg'
import studio_dol_13 from '../../images/studio_dol/13.jpg'

import studio_dol_1_thumbnail from '../../images/studio_dol_thumbnail/1_thumbnail.jpg'
import studio_dol_2_thumbnail from '../../images/studio_dol_thumbnail/2_thumbnail.jpg'
import studio_dol_3_thumbnail from '../../images/studio_dol_thumbnail/3_thumbnail.jpg'
import studio_dol_4_thumbnail from '../../images/studio_dol_thumbnail/4_thumbnail.jpg'
import studio_dol_5_thumbnail from '../../images/studio_dol_thumbnail/5_thumbnail.jpg'
import studio_dol_6_thumbnail from '../../images/studio_dol_thumbnail/6_thumbnail.jpg'
import studio_dol_7_thumbnail from '../../images/studio_dol_thumbnail/7_thumbnail.jpg'
import studio_dol_8_thumbnail from '../../images/studio_dol_thumbnail/8_thumbnail.jpg'
import studio_dol_9_thumbnail from '../../images/studio_dol_thumbnail/9_thumbnail.jpg'
import studio_dol_10_thumbnail from '../../images/studio_dol_thumbnail/10_thumbnail.jpg'
import studio_dol_11_thumbnail from '../../images/studio_dol_thumbnail/11_thumbnail.jpg'
import studio_dol_12_thumbnail from '../../images/studio_dol_thumbnail/12_thumbnail.jpg'


function RoomsPage(props)
{
    const [darkboxRef, setDarkboxRef] = useState(null)
    const onDarkboxRefChange = useCallback(node => {
        setDarkboxRef(node)
    }, [])

    const images_pokoj_2os = [
        {src: pokoj_2os_0_thumbnail, fullSrc: pokoj_2os_0, alt: "pokoj 2os 0", index: 0},
        {src: pokoj_2os_1_thumbnail, fullSrc: pokoj_2os_1, alt: "pokoj 2os 1", index: 1},
        {src: pokoj_2os_2_thumbnail, fullSrc: pokoj_2os_2, alt: "pokoj 2os 2", index: 2},
        {src: pokoj_2os_3_thumbnail, fullSrc: pokoj_2os_3, alt: "pokoj 2os 3", index: 3},
        {src: pokoj_2os_4_thumbnail, fullSrc: pokoj_2os_4, alt: "pokoj 2os 4", index: 4},
        {src: pokoj_2os_5_thumbnail, fullSrc: pokoj_2os_5, alt: "pokoj 2os 5", index: 5},
        {src: pokoj_2os_6_thumbnail, fullSrc: pokoj_2os_6, alt: "pokoj 2os 6", index: 6},
    ]

    const images_pokoj_3os_balkon = [
        {src: pokoj_3os_balkon_0_thumbnail, fullSrc: pokoj_3os_balkon_0, alt: "pokoj 3os balkon 0", index: 0},
        {src: pokoj_3os_balkon_1_thumbnail, fullSrc: pokoj_3os_balkon_1, alt: "pokoj 3os balkon 1", index: 1},
        {src: pokoj_3os_balkon_2_thumbnail, fullSrc: pokoj_3os_balkon_2, alt: "pokoj 3os, balkon 2", index: 2},
        {src: pokoj_3os_balkon_5_thumbnail, fullSrc: pokoj_3os_balkon_5, alt: "pokoj 3os, balkon 5", index: 5},
        {src: pokoj_3os_balkon_6_thumbnail, fullSrc: pokoj_3os_balkon_6, alt: "pokoj 3os, balkon 6", index: 6},
    ]

    const images_pokoj_3os = [
        {src: pokoj_3os_0_thumbnail, fullSrc: pokoj_3os_0, alt: "pokoj 3os 0", index: 0},
        {src: pokoj_3os_1_thumbnail, fullSrc: pokoj_3os_1, alt: "pokoj 3os 1", index: 1},
        {src: pokoj_3os_2_thumbnail, fullSrc: pokoj_3os_2, alt: "pokoj 3os 2", index: 2},
        {src: pokoj_3os_3_thumbnail, fullSrc: pokoj_3os_3, alt: "pokoj 3os 3", index: 3},
        {src: pokoj_3os_4_thumbnail, fullSrc: pokoj_3os_4, alt: "pokoj 3os 4", index: 4},
    ]

    const images_studio_gora = [
        {src: studio_gora_1_thumbnail, fullSrc: studio_gora_1, alt: "studio gora 1", index: 0},
        {src: studio_gora_2_thumbnail, fullSrc: studio_gora_2, alt: "studio gora 2", index: 1},
        {src: studio_gora_3_thumbnail, fullSrc: studio_gora_3, alt: "studio gora 3", index: 2},
        {src: studio_gora_4_thumbnail, fullSrc: studio_gora_4, alt: "studio gora 4", index: 3},
        {src: studio_gora_5_thumbnail, fullSrc: studio_gora_5, alt: "studio gora 5", index: 4},
        {src: studio_gora_6_thumbnail, fullSrc: studio_gora_6, alt: "studio gora 6", index: 5},
        {src: studio_gora_7_thumbnail, fullSrc: studio_gora_7, alt: "studio gora 7", index: 6},
        {src: studio_gora_8_thumbnail, fullSrc: studio_gora_8, alt: "studio gora 8", index: 7},
        {src: studio_gora_9_thumbnail, fullSrc: studio_gora_9, alt: "studio gora 9", index: 8},
        {src: studio_gora_10_thumbnail, fullSrc: studio_gora_10, alt: "studio gora 10", index: 9},
        {src: studio_gora_11_thumbnail, fullSrc: studio_gora_11, alt: "studio gora 11", index: 10},
        {src: studio_gora_12_thumbnail, fullSrc: studio_gora_12, alt: "studio gora 12", index: 11},
        {src: studio_gora_13_thumbnail, fullSrc: studio_gora_13, alt: "studio gora 13", index: 12},
    ]    
    
    const images_studio_dol = [
        {src: studio_dol_1_thumbnail, fullSrc: studio_dol_1, alt: "studio dol 1", index: 0},
        {src: studio_dol_2_thumbnail, fullSrc: studio_dol_2, alt: "studio dol 2", index: 1},
        {src: studio_dol_3_thumbnail, fullSrc: studio_dol_3, alt: "studio dol 3", index: 2},
        {src: studio_dol_4_thumbnail, fullSrc: studio_dol_4, alt: "studio dol 4", index: 3},
        {src: studio_dol_5_thumbnail, fullSrc: studio_dol_5, alt: "studio dol 5", index: 4},
        {src: studio_dol_6_thumbnail, fullSrc: studio_dol_6, alt: "studio dol 6", index: 5},
        {src: studio_dol_7_thumbnail, fullSrc: studio_dol_7, alt: "studio dol 7", index: 6},
        {src: studio_dol_8_thumbnail, fullSrc: studio_dol_8, alt: "studio dol 8", index: 7},
        {src: studio_dol_9_thumbnail, fullSrc: studio_dol_9, alt: "studio dol 9", index: 8},
        {src: studio_dol_10_thumbnail, fullSrc: studio_dol_10, alt: "studio dol 10", index: 9},
        {src: studio_dol_11_thumbnail, fullSrc: studio_dol_11, alt: "studio dol 11", index: 10},
        {src: studio_dol_12_thumbnail, fullSrc: studio_dol_12, alt: "studio dol 12", index: 11},
    ]


    return (
        <div className="content">
            <h3>
                Pokój 2-osobowy z balkonem
            </h3>
            <Gallery images={images_pokoj_2os} />
            <h3>
                Pokój 3-osobowy z balkonem
            </h3>
            <Gallery images={images_pokoj_3os_balkon} />
            <h3>
                Pokój 3-osobowy
            </h3>
            <Gallery images={images_pokoj_3os} />
            <h3>
                Studio 4-osobowe 1 (2 pokoje, 33 m2)
            </h3>
            <Gallery images={images_studio_gora} />
            <h3>
                Studio 4-osobowe 2 (2 pokoje, 33 m2)
            </h3>
            <Gallery images={images_studio_dol} />

            {/* <ExpandableImage imageSrc={test_image_0} fullImageSrc={test_image_0} imageAlt="test image" index={0} darkbox={darkboxRef}/>
            <ExpandableImage imageSrc={test_image_1} fullImageSrc={test_image_1} imageAlt="test image" index={32} darkbox={darkboxRef}/>
            <ExpandableImage imageSrc={test_image_2} fullImageSrc={test_image_2} imageAlt="test image" index={2} darkbox={darkboxRef}/>
            <ExpandableImage imageSrc={test_image_3} fullImageSrc={test_image_3} imageAlt="test image" index={3} darkbox={darkboxRef}/>
            <ExpandableImage imageSrc={test_image_4} fullImageSrc={test_image_4} imageAlt="test image" index={4} darkbox={darkboxRef}/> */}

            <Darkbox ref={onDarkboxRefChange}/>
        </div>
    )
}

export default RoomsPage
