import React from "react"
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import './App.css';
import MainMenu from './components/MainMenu/MainMenu.js'
import MainPage from './components/MainPage/MainPage.js'
import RoomsPage from './components/RoomsPage/RoomsPage.js'
import PricelistPage from './components/PricelistPage/PricelistPage.js'
import LocationPage from './components/LocationPage/LocationPage.js'
import ContactPage from './components/ContactPage/ContactPage.js'
import Footer from './components/Footer/Footer.js'

function App()
{
    const homePath = ""
    const menuOptions = [['Oferta', '/'], ['Pokoje', '/rooms'], ['Cennik', '/pricelist'], ['Lokalizacja', '/location'], ['Kontakt', '/contact']]

    const Content = () => {
        return (
            <BrowserRouter>
                <MainMenu menuOptions={menuOptions} homePath={homePath} />
                <Routes>
                    <Route exact path={`${homePath}/`} element={<MainPage />}></Route>
                    <Route exact path={`${homePath}/rooms`} element={<RoomsPage />}></Route>
                    <Route exact path={`${homePath}/pricelist`} element={<PricelistPage />}></Route>
                    <Route exact path={`${homePath}/location`} element={<LocationPage />}></Route>
                    <Route exact path={`${homePath}/contact`} element={<ContactPage />}></Route>
                    <Route path="*" element={<MainPage />} />
                </Routes>
            </BrowserRouter>
        )
    }

    return (
        <div className="App">
            <header className="hero">
                <div className="center_vertically">
                    <h1>Rubin</h1>
                    <h2>Dom gościnny</h2>
                </div>
            </header>
            <Content />
            <Footer />
        </div>
    )
}

export default App;
